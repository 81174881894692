<template>
    <Navbar :user="user" />
    <section class="courses-details-area pb-70" v-if="!isLoading">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">
            <div class="courses-details-desc text-start">
                <h2 class="text-center">{{ quiz.title }}</h2>
                <h4 class="text-center">Quiz Summary</h4>
                <div class="row">
                  <div class="col-md-4 offset-md-4">
                    <table class="table table-hover table-sm">
                      <tbody>
                        <tr>
                          <td>Your Score:</td>
                          <td>{{ quiz_results.score }}</td>
                        </tr>
                        <tr>
                          <th>Total Score:</th>
                          <th>{{ quiz_results.total_question }}</th>
                        </tr>
                        <tr>
                          <td>Summary:</td>
                          <td>
                            <span v-if="(quiz_results.score / quiz_results.total_question ) * 100 >= 50" class="text-success">
                              Passed
                            </span>
                            <span v-else class="text-danger">
                              Failed
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <NextChapter :course_content_id="quiz.course_content_id" />
            </div>
          </div>

        </div>
      </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import moment from 'moment'
import NextChapter from '@/components/questions/NextChapter.vue'
export default {
 components: { Navbar, Loading, NextChapter },
  data(){
    return {
      isLoading: true,
      subscriptionStatus: 0,
      rating: 4.3,
      quiz: {},
      user:{},
      quiz_results:{},
      question: {},
      token: localStorage.getItem('user_data'),
    }
  },
  methods:{
    getQuiz(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/quiz/profile/' + id).then(response => {
        this.quiz = response.data
        document.title = this.quiz.title +' - Training'
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getQuizResults(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/quiz/get_quiz_result/' + id).then(response => {
        this.quiz_results = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getQuiz()
    this.getQuizResults()
    this.moment = moment
  }
}
</script>

<style>

</style>